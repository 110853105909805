/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive, appMenus } from "../../../../_helpers";

export function RenderMenu({ layoutProps, permissions, tab }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url, false) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
    };

    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {
                    appMenus.map((menu, i) => {
                        return (menu.tab === tab) ?
                            menu.section.map((sect, j) => {
                                return (
                                    <React.Fragment key={'sect' + j}>
                                        <li className={"menu-section " + ((sect.title === "" || !permissions.includes(sect.permission)) ? "d-none" : "")}>
                                            <h4 className="menu-text">{sect.title}</h4>
                                            <i className="menu-icon flaticon-more-v2"></i>
                                        </li>
                                        {
                                            sect.submenu.map((sub, k) => {
                                                return (permissions.includes(sub.permission) || sub.permission === "") ?
                                                    <li className={`menu-item ${getMenuItemActive(sub.page, false)}`} aria-haspopup="true" key={'submenu-' + k}>
                                                        <NavLink className="menu-link" to={sub.page}>
                                                            <span className="svg-icon menu-icon">
                                                                <SVG src={toAbsoluteUrl(sub.icon)} />
                                                            </span>
                                                            <span className="menu-text">{sub.title}</span>
                                                        </NavLink>
                                                    </li> : null
                                            })
                                        }
                                    </React.Fragment>
                                )
                            }) : null
                    })
                }
            </ul>
        </>
    );
}