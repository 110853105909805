import {createSlice} from "@reduxjs/toolkit";

const initialApprovalCommitteesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalApproversCount: 0,
  entities: null,
  meetings: null,
  events: null,
  totalMeetingsCount: 0,
  approvers: null,
  userProfiles: null,
  userProfilesCount: 0,

  totalApprovalCommitteeMeetingLicenses: 0,
  approvalCommitteeMeetingLicenses: null,

  totalAllApprovalCommitteeMeetingLicenses: 0,
  allApprovalCommitteeMeetingLicenses: null,

  totalApprovalCommitteeMeetingApprovedLicenses: 0,
  approvalCommitteeMeetingApprovedLicenses: null,

  totalApprovalCommitteeMeetingVotedLicenses: 0,
  approvalCommitteeMeetingVotedLicenses: null,


  totalApprovalCommitteeMeetingTariffs: 0,
  approvalCommitteeMeetingTariffs: null,

  totalAllApprovalCommitteeMeetingTariffs: 0,
  allApprovalCommitteeMeetingTariffs: null,

  totalApprovalCommitteeMeetingApprovedTariffs: 0,
  approvalCommitteeMeetingApprovedTariffs: null,
  
  totalApprovalCommitteeMeetingVotedTariffs: 0,
  approvalCommitteeMeetingVotedTariffs: null,


  totalApprovalCommitteeMeetingExpressionOfInterests: 0,
  approvalCommitteeMeetingExpressionOfInterests: null,

  totalAllApprovalCommitteeMeetingExpressionOfInterests: 0,
  allApprovalCommitteeMeetingExpressionOfInterests: null,

  totalApprovalCommitteeMeetingApprovedExpressionOfInterests: 0,
  approvalCommitteeMeetingApprovedExpressionOfInterests: null,
  
  totalApprovalCommitteeMeetingVotedExpressionOfInterests: 0,
  approvalCommitteeMeetingVotedExpressionOfInterests: null,


  approvalCommitteeForEdit: undefined,
  approvalCommitteeForView: undefined,
  approvalCommitteeMeetingForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const approvalCommitteesSlice = createSlice({
  name: "approvalCommittees",
  initialState: initialApprovalCommitteesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.error(state.error);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
  
    approvalCommitteeFetched: (state, action) => {
      state.actionsLoading = false;
      state.approvalCommitteeForEdit = action.payload.approvalCommitteeForEdit;
      state.approvalCommitteeForView = action.payload.approvalCommitteeForEdit;
      state.error = null;
    },
    approvalCommitteesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    fetchedUserProfiles: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userProfiles = entities;
      state.userProfilesCount = totalCount;
    },

    approvalCommitteeMeetingsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.meetings = entities;
      var eventEntities = [];
      entities.map((e => {
        var evt = {};
        evt.id = e._id;
        evt.allDay = true;
        evt.start = e.meetingDate;
        evt.title = e.agenda;
        evt.url = '/licensing/settings/committees/view-meeting/'+e._id;
        eventEntities = [...eventEntities,evt];
        return false;
      }))
      state.events = eventEntities;
      state.totalMeetingsCount = totalCount;
    },



    allApprovalCommitteeMeetingExpressionOfInterestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allApprovalCommitteeMeetingExpressionOfInterests = entities;
      state.totalAllApprovalCommitteeMeetingExpressionOfInterests = totalCount;
    },
    approvalCommitteeMeetingExpressionOfInterestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingExpressionOfInterests = entities;
      state.totalApprovalCommitteeMeetingExpressionOfInterests = totalCount;
    },
    approvalCommitteeMeetingApprovedExpressionOfInterestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingApprovedExpressionOfInterests = entities;
      state.totalApprovalCommitteeMeetingApprovedExpressionOfInterests = totalCount;
    },
    approvalCommitteeMeetingVotedExpressionOfInterestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingVotedExpressionOfInterests = entities;
      state.totalApprovalCommitteeMeetingVotedExpressionOfInterests = totalCount;
    },



    allApprovalCommitteeMeetingTariffsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allApprovalCommitteeMeetingTariffs = entities;
      state.totalAllApprovalCommitteeMeetingTariffs = totalCount;
    },
    approvalCommitteeMeetingTariffsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingTariffs = entities;
      state.totalApprovalCommitteeMeetingTariffs = totalCount;
    },
    approvalCommitteeMeetingApprovedTariffsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingApprovedTariffs = entities;
      state.totalApprovalCommitteeMeetingApprovedTariffs = totalCount;
    },
    approvalCommitteeMeetingVotedTariffsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingVotedTariffs = entities;
      state.totalApprovalCommitteeMeetingVotedTariffs = totalCount;
    },

   
    allApprovalCommitteeMeetingLicensesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allApprovalCommitteeMeetingLicenses = entities;
      state.totalAllApprovalCommitteeMeetingLicenses = totalCount;
    },
    approvalCommitteeMeetingLicensesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingLicenses = entities;
      state.totalApprovalCommitteeMeetingLicenses = totalCount;
    },
    approvalCommitteeMeetingApprovedLicensesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingApprovedLicenses = entities;
      state.totalApprovalCommitteeMeetingApprovedLicenses = totalCount;
    },
    approvalCommitteeMeetingVotedLicensesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvalCommitteeMeetingVotedLicenses = entities;
      state.totalApprovalCommitteeMeetingVotedLicenses = totalCount;
    },






    approvalCommitteeMeetingFetched: (state, action) => {
      state.actionsLoading = false;
      state.approvalCommitteeMeetingForEdit = action.payload.approvalCommitteeMeetingForEdit;
      state.error = null;
    },
    approvalCommitteeMeetingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.meetings.push(action.payload.approvalCommitteeMeeting);
    },
    approvalCommitteeMeetingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.approvalCommitteeMeetingForEdit = action.payload.approvalCommitteeMeeting;
      // state.meetings = state.meetings.map(entity => {
      //   if (entity._id === action.payload.approvalCommitteeMeeting._id) {
      //     var newEntity = action.payload.approvalCommitteeMeeting;
      //     newEntity.id = entity.id;
      //     return newEntity;
      //   }
      //   return entity;
      // });
    },
    // deleteApprovalCommittee
    approvalCommitteeMeetingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.meetings.filter(el => el.id !== action.payload.id);
    },

    approversFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvers = entities;
      state.totalApproversCount = totalCount;
    },
    // createApprovalCommittee
    approvalCommitteeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.approvalCommittee);
    },
    // updateApprovalCommittee
    approvalCommitteeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity._id === action.payload.approvalCommittee._id) {
          var newEntity = action.payload.approvalCommittee;
          newEntity.id = entity.id;
          return newEntity;
        }
        return entity;
      });
    },
    // deleteApprovalCommittee
    approvalCommitteeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteApprovalCommittees
    approvalCommitteesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // approvalCommitteesUpdateState
    approvalCommitteesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
