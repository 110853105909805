/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
// import { useHistory, Link } from "react-router-dom";
import { Link } from "react-router-dom";
import { toAbsoluteUrl,appConfigs } from "../../../../_helpers"; //, appConfigs
import { shallowEqual, useSelector } from "react-redux";


export function QuickUser() {
  // const history = useHistory();

  const logoutClick = () => {
      const toggle = document.getElementById("kt_quick_user_toggle");
      if (toggle) {
        toggle.click();
      }
      // history.push("/logout");
      window.location.href = "/logout";
  };

  // Close sidebar on route change
  // history.listen((location, action) => {
    // const toggle = document.getElementById("kt_quick_user_toggle");
    // if (toggle) {
      // toggle.click();
    // }
    // console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
    // console.log(`The last navigation action was ${action}`);
  // });

  // Users Redux state
  // const dispatch = useDispatch();
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );
  
  // console.log(user);

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
            {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
          </h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              <div className="symbol-label" style={{backgroundImage: `url(${user?.pic?appConfigs.uploads_url + 'avatars/' + user.pic:toAbsoluteUrl("/media/users/blank.png")})`}}/>
              <i className="symbol-badge bg-success"/>
            </div>
            <div className="d-flex flex-column">
            <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary- txt-trunc" >{user?.first_name} {user?.last_name}</span>
              {/* <div className="text-muted mt-1">{user.phone}</div> */}
              <div className="navi mt-2">
                <span className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-notification.svg")}></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary- txt-trunc">{user?.email}</span>
                </span>
                </span>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-danger btn-bold" onClick={logoutClick}>Sign out</button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>

          <div className="navi navi-spacer-x-0 p-0">
          
            <Link to="/profile/personal-info" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">
                    Update personal information
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/profile/change-password" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Change Password</div>
                  <div className="text-muted">Change account password</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
  );
}
