export const appMenus = [
    {
        title: "Dashboard",
        icon: "/media/svg/icons/Devices/Display1.svg",
        page: "/dashboard",
        tab: "tabOverview",
        permission: "",
        section: [
            {
                title: "Dashboard",
                permission: "",
                submenu: [
                    {
                        title: "Dashboard",
                        icon: "/media/svg/icons/General/Attachment1.svg",
                        page: "/dashboard",
                        permission: "",
                    },
                    {
                        title: "Downloads",
                        icon: "/media/svg/icons/Files/Download.svg",
                        page: "/downloads",
                        permission: "",
                    },
                    {
                        title: "Insurance Providers",
                        icon: "/media/svg/icons/General/Shield-check.svg",
                        page: "/insurance-providers",
                        permission: "",
                    },
                ],
            },
        ],
    },
    {
        title: "Micro Insurance",
        icon: "/media/svg/icons/General/Shield-protected.svg",
        page: "/insurance-providers",
        tab: "tabInsurance",
        permission: "",
        section: [
            {
                title: "My Applications",
                permission: "",
                submenu: [
                    {
                        title: "Insurance Providers",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/insurance-providers/",
                        permission: "",
                    },
                    {
                        title: "Livestock Insurance",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/insurance-providers/livestock",
                        permission: "",
                    },
                    {
                        title: "Crop Insurance",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/insurance-providers/crop",
                        permission: "",
                    },
                    {
                        title: "Insurance Reports",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/insurance-providers/reports",
                        permission: "",
                    },
                ],
            },
        ],
    },
    {
        title: "User Management",
        icon: "/media/svg/icons/Communication/Group.svg",
        page: "/user-management",
        tab: "tabUsers",
        permission: "",
        section: [
            {
                title: "User Management",
                permission: "",
                submenu: [
                    {
                        title: "Manage Users",
                        icon: "/media/svg/icons/Communication/Group.svg",
                        page: "/user-management/users",
                        permission: "",
                    },
                    {
                        title: "Manage Roles",
                        icon: "/media/svg/icons/Code/Compiling.svg",
                        page: "/user-management/roles",
                        permission: "",
                    },
                    {
                        title: "Manage Committees",
                        icon: "/media/svg/icons/Code/Control.svg",
                        page: "/licensing/settings/approval-committees",
                        permission: "",
                    },
                ],
            },
        ],
    },
];