import axios from "axios";
import { appConfigs } from "../../../../_igad/_helpers";

export const GET_USER_BY_ACCESSTOKEN_URL = `${appConfigs.api_url }/user/verify-user-token`
export const LOGIN_URL = `${appConfigs.api_url}/user/login`
export const REGISTER_URL = `${appConfigs.api_url}/user/sign-up`
export const REQUEST_PASSWORD_URL = `${appConfigs.api_url}/user/forgot-password`

export function login(email, password) {
  return axios.post(appConfigs.api_url + 'user/login', { email, password });
}

export function logout() {
  return axios.post(appConfigs.api_url + 'logout', { });
}

export function register(password, email, firstname, lastname, gender, confirmPassword, organization,country,phone,serviceCategory,accountType,areaOfInterest,otherServiceCategory,companyName,companyAddress,companyEmail,companyPhone,companyWebsite){
  return axios.post(appConfigs.api_url + 'user/sign-up', {
    email,
    first_name: firstname,
    last_name: lastname,
    gender,
    password,
    confirmPassword,
    organization,
    country,
    phone,
    serviceCategory,
    accountType,
    areaOfInterest,
    otherServiceCategory,
    companyName,
    companyAddress,
    companyEmail,
    companyPhone,
    companyWebsite });
}

export function resetPassword(email) {
  return axios.post(appConfigs.api_url + 'user/reset-password', { email });
}

export function forgotPassword(email) {
  return axios.post(appConfigs.api_url + 'user/forgot-password', { email });
}

export function newPassword(password, reset_code) {
  return axios.post(appConfigs.api_url + 'user/reset-password', { new_password:password, repeat_new_password:password, token:reset_code });
}

export function verifyEmail(code) {
  return axios.post(appConfigs.api_url + 'user/verify-email', { code });
}

export function resendVerification(email) {
  return axios.post(appConfigs.api_url + 'user/resend-verification', { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(appConfigs.api_url + 'user/verify-user-token');
}

export function validatePortalToken(token) {
  return axios.post(appConfigs.api_url + 'user/verify-user-token', { api_token:token });
}
