import React from "react";
import { Popover } from "react-bootstrap";

export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const showDate = (timestamp) => {
  if(isNaN(timestamp)){
    var valid = (new Date(timestamp)).getTime() > 0;
    if(valid){
      return new Date(timestamp).toLocaleDateString("en-US",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }); 
    } else {
      return "";
    }
  } else {
    return new Date(parseFloat(timestamp)).toLocaleDateString("en-US",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }); 
  }
  
}

export const showHelpBubble = (title='',text='') => {
    return (
        <Popover id="popover-basic">
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>
            {text}
          </Popover.Content>
        </Popover>
      );
}

export const getFileIcon = (ext = '') => {
  if(ext.match(/xls/ig)?.length != null){
      return "detailed/excel";
  } else if(ext.match(/pdf/ig)?.length != null){
      return "pdf";
  } else if(ext.match(/doc/ig)?.length != null){
      return "doc";
  } else if(ext.match(/csv/ig)?.length != null){
      return "csv";
  } else if(ext.match(/mp4|mp3|ogg/ig)?.length != null){
      return "mp4";
  } else if(ext.match(/zip/ig)?.length != null){
      return "zip";
  } else if(ext.match(/jpg|png|gif|jpeg/ig)?.length != null){
      return "jpg";
  } else {
      return "zip";
  }
}
