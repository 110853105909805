/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
//load landing css file
import "./landing.css";

export function LandingPage() {
  return (
    <div
      id="kt_body"
      data-bs-spy="scroll"
      data-bs-target="#kt_landing_menu"
      className="bg-white position-relative app-blank"
    >
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        <div className="mb-0" id="home">
          <div
            className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
            style={{
              backgroundImage: "url(/media/svg/illustrations/landing.svg)",
              backgroundColor: "#13263c",
            }}
          >
            <div
              className="landing-header"
              data-kt-sticky="true"
              data-kt-sticky-name="landing-header"
              data-kt-sticky-offset="{default: '200px', lg: '300px'}"
              style={{ paddingTop: "35px", paddingBottom: "20px" }}
            >
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center flex-equal">
                    <button
                      className="btn btn-icon btn-active-color-primary mx-3 d-flex d-lg-none"
                      id="kt_landing_menu_toggle"
                    >
                      <span className="svg-icon svg-icon-2hx">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </button>

                    <Link to="/">
                      <img
                        alt="Logo"
                        src="/media/logos/ip-logo5.png"
                        className="logo-default h-25px h-lg-30px"
                      />
                    </Link>
                  </div>

                  <div className="d-lg-block" id="kt_header_nav_wrapper">
                    <div
                      className="d-lg-block p-5 p-lg-0"
                      data-kt-drawer="true"
                      data-kt-drawer-name="landing-menu"
                      data-kt-drawer-activate="{default: true, lg: false}"
                      data-kt-drawer-overlay="true"
                      data-kt-drawer-width="200px"
                      data-kt-drawer-direction="start"
                      data-kt-drawer-toggle="#kt_landing_menu_toggle"
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                    >
                      <div
                        className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                        id="kt_landing_menu"
                      >
                        <div className="menu-item">
                          <a
                            className="menu-link nav-link active py-3 px-4 px-xxl-6"
                            href="#kt_body"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Home
                          </a>
                        </div>

                        <div className="menu-item">
                          <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#what-it-does"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            What it does
                          </a>
                        </div>
                        <div className="menu-item">
                          <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#how-it-works"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            How it works
                          </a>
                        </div>

                        <div className="menu-item">
                          <a
                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                            href="#achievements"
                            data-kt-scroll-toggle="true"
                            data-kt-drawer-dismiss="true"
                          >
                            Achievements
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex-equal text-end ms-1">
                    <Link to="/auth" className="btn btn-success">
                      Sign In
                    </Link>
                  </div>
                  <div className="flex-equal text-end ms-1">
                    <Link to="/auth/signup" className="btn btn-success">
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
              <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">
                  <span
                    style={{
                      background:
                        "linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    <span id="kt_landing_hero_text">
                      Communication Platform for CoP on Risk Transfer and
                      Micro-insurance
                    </span>
                  </span>
                </h1>

                <Link to={"/auth"} className="btn btn-success">
                  Get Started
                </Link>
              </div>

              <div className="d-flex flex-center flex-wrap position-relative px-5">
                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Djibouti"
                >
                  <img
                    src="/media/country-flags/djibouti.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Eritrea"
                >
                  <img
                    src="/media/country-flags/Eritrea.jpeg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Ethiopia"
                >
                  <img
                    src="/media/country-flags/ethiopia.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Somalia"
                >
                  <img
                    src="/media/country-flags/somalia.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Kenya"
                >
                  <img
                    src="/media/country-flags/kenya.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Sudan"
                >
                  <img
                    src="/media/country-flags/sudan.jpeg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="South Sudan"
                >
                  <img
                    src="/media/country-flags/south-sudan.jpg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>

                <div
                  className="d-flex flex-center m-3 m-md-6"
                  data-bs-toggle="tooltip"
                  title="Uganda"
                >
                  <img
                    src="/media/country-flags/uganda.svg"
                    className="mh-30px mh-lg-40px"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="#13263c"
              ></path>
            </svg>
          </div>
        </div>

        <div className="mb-n10 mb-lg-n20 z-index-2">
          <div className="container">
            <div className="text-center mb-17">
              <h3
                className="fs-2hx  mb-5"
                id="what-it-does"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                What it does
              </h3>

              <div className="fs-5 text-muted fw-bold">
                Share and collaborate on Risk Transfer and Micro Insurance with
                both private and public sector partners in the IGAD region.
              </div>
            </div>
            <div className="text-center mb-17">
              <h3
                className="fs-2hx  mb-5"
                id="how-it-works"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                How it Works
              </h3>

              {/* <div className="fs-5 text-muted fw-bold">Share and Collaborate on Risk Transfer and Micro-insurance and other partners working on similar initiatives within the IGAD region both in the private and public sectors.</div> */}
            </div>

            <div className="row w-100 gy-10 mb-md-20">
              <div className="col-md-6 px-5">
                <div className="text-center pe-5 mb-10 mb-md-0">
                  <img
                    src="/media/illustrations/sketchy-1/2.png"
                    className="mh-125px mb-9"
                    alt=""
                  />

                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 mx-3 fs-3">
                      1
                    </span>

                    <div className="fs-5 fs-lg-3 fw-bold  text-black ">
                      Create an Account
                    </div>
                  </div>

                  <div className="fw-semibold fs-6 fs-lg-4 text-muted text-center">
                    <ul style={{ listStyle: "none" }}>
                      <li>1. Register on the portal</li>
                      <li>2. Setup your profile </li>
                      <li>3. Add your details and sector</li>
                      <li>4. Connect with experts</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-10">&nbsp;</div>
              </div>

              <div className="col-md-6 px-5">
                <div className="text-center mb-10 mb-md-0 pe-5">
                  <img
                    src="/media/illustrations/sketchy-1/12.png"
                    className="mh-125px mb-9"
                    alt=""
                  />

                  <div className="d-flex flex-center mb-5">
                    <span className="badge badge-circle badge-light-success fw-bold p-5 mx-3 fs-3">
                      2
                    </span>

                    <div className="fs-5 fs-lg-3 fw-bold text-black ">
                      Collaborate
                    </div>
                  </div>

                  <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                    <ul style={{ listStyle: "none" }}>
                      <li>1. Engage experts in chat</li>
                      <li>2. Join seminars and conferences </li>
                      <li>3. Download available reports</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-10">&nbsp;</div>
              </div>
            </div>

            <div className="tns tns-default mt-lg-n100 mt-sm-n100">&nbsp;</div>
          </div>
        </div>

        <div className="mt-sm-n10">
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="#13263c"
              ></path>
            </svg>
          </div>

          <div
            className="pb-15 pt-18 landing-dark-bg"
            style={{ backgroundColor: "#13263c" }}
          >
            <div className="container">
              <div
                className="text-center mt-15 mb-18"
                id="achievements"
                data-kt-scroll-offset="{default: 100, lg: 150}"
              >
                <h3 className="fs-2hx text-white fw-bold mb-5">
                  Our Achievements
                </h3>

                <div className="fs-5 text-gray-700 fw-bold">
                  Our impact in the region
                </div>
              </div>
              <div className="d-flex flex-center">
                <div className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                  <div
                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{
                      backgroundImage: "url('/media/svg/misc/octagon.svg')",
                    }}
                  >
                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                      <i
                        className="human-icon icon-indigenous-people"
                        style={{ fontSize: "40px" }}
                      >
                        &#xe829;
                      </i>
                    </span>

                    <div className="mb-0">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value="700"
                          data-kt-countup-suffix="+"
                        >
                          297M
                        </div>
                      </div>

                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Population (World Bank, 2021)
                      </span>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{
                      backgroundImage: "url('/media/svg/misc/octagon.svg')",
                    }}
                  >
                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                      <i
                        className="human-icon icon-top-ranking"
                        style={{ fontSize: "40px" }}
                      >
                        &#xe824;
                      </i>
                    </span>

                    <div className="mb-0">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value="80"
                          data-kt-countup-suffix="K+"
                        >
                          322B
                        </div>
                      </div>

                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        (World Bank 2021, IGAD 2022)
                      </span>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                    style={{
                      backgroundImage: "url('/media/svg/misc/octagon.svg')",
                    }}
                  >
                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                      <i
                        className="human-icon icon-Road-affected"
                        style={{ fontSize: "40px" }}
                      >
                        &#xe9e5;
                      </i>
                    </span>

                    <div className="mb-0">
                      <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                        <div
                          className="min-w-70px"
                          data-kt-countup="true"
                          data-kt-countup-value="35"
                          data-kt-countup-suffix="M+"
                        >
                          5.2M Km<sup>2</sup>
                        </div>
                      </div>

                      <span className="text-gray-600 fw-semibold fs-5 lh-0">
                        Area Covered
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="fs-2 fw-semibold text-muted text-center mb-3">
						<span className="fs-1 lh-1 text-gray-700">“</span>IGAD to be the premier Regional Economic Community (REC) for 
						<br />
						<span className="text-gray-700 mx-1">achieving peace and </span> sustainable development in the region
						<span className="fs-1 lh-1 text-gray-700">“</span></div> */}
            </div>
          </div>

          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 12 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                fill="#13263c"
              ></path>
            </svg>
          </div>
        </div>

        {/* <div className="mb-lg-n15 position-relative z-index-2">
				
				<div className="container">
					
					<div className="card" style={{filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))"}}>
						
						<div className="card-body p-lg-20">
							
							<div className="text-center mb-5 mb-lg-10">
								
								<h3 className="fs-2hx  mb-5" id="portfolio" data-kt-scroll-offset="{default: 100, lg: 250}">Our Projects</h3>
								
							</div>
							
							
							<div className="d-flex flex-center mb-5 mb-lg-15">
								
								<ul className="nav border-transparent flex-center fs-5 fw-bold">
									<li className="nav-item">
										<a className="nav-link text-gray-500 text-active-primary px-3 px-lg-6 active" href="#" data-bs-toggle="tab" data-bs-target="#kt_landing_projects_latest">Peace & Security</a>
									</li>
									<li className="nav-item">
										<a className="nav-link text-gray-500 text-active-primary px-3 px-lg-6" href="#" data-bs-toggle="tab" data-bs-target="#kt_landing_projects_web_design">Agriculture & Environment</a>
									</li>
									<li className="nav-item">
										<a className="nav-link text-gray-500 text-active-primary px-3 px-lg-6" href="#" data-bs-toggle="tab" data-bs-target="#kt_landing_projects_mobile_apps">Economic Cooperation & Regional Integration</a>
									</li>
									<li className="nav-item">
										<a className="nav-link text-gray-500 text-active-primary px-3 px-lg-6" href="#" data-bs-toggle="tab" data-bs-target="#kt_landing_projects_development">Health & Social Development</a>
									</li>
								</ul>
								
							</div>
							
							
							<div className="tab-content">
								
								<div className="tab-pane fade show active" id="kt_landing_projects_latest">
									
									<div className="row g-10">
										
										<div className="col-lg-6">
											
											<a className="d-block card-rounded overlay h-lg-100" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-23.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-23.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
										
										<div className="col-lg-6">
											
											<div className="row g-10 mb-10">
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-22.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-22.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-21.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-21.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
											</div>
											
											
											<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x400/img-20.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-20.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
									</div>
									
								</div>
								
								
								<div className="tab-pane fade" id="kt_landing_projects_web_design">
									
									<div className="row g-10">
										
										<div className="col-lg-6">
											
											<a className="d-block card-rounded overlay h-lg-100" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-11.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-11.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
										
										<div className="col-lg-6">
											
											<div className="row g-10 mb-10">
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-12.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-12.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-21.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-21.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
											</div>
											
											
											<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x400/img-20.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-20.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
									</div>
									
								</div>
								
								
								<div className="tab-pane fade" id="kt_landing_projects_mobile_apps">
									
									<div className="row g-10">
										
										<div className="col-lg-6">
											
											<div className="row g-10 mb-10">
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-16.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-16.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-12.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-12.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
											</div>
											
											
											<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x400/img-15.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-15.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
										
										<div className="col-lg-6">
											
											<a className="d-block card-rounded overlay h-lg-100" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-23.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-23.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
									</div>
									
								</div>
								
								
								<div className="tab-pane fade" id="kt_landing_projects_development">
									
									<div className="row g-10">
										
										<div className="col-lg-6">
											
											<a className="d-block card-rounded overlay h-lg-100" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-15.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-15.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
										
										<div className="col-lg-6">
											
											<div className="row g-10 mb-10">
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-22.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-22.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
												
												<div className="col-lg-6">
													
													<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x600/img-21.jpg">
														
														<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-21.jpg')"}}></div>
														
														
														<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
															<i className="bi bi-eye-fill fs-3x text-white"></i>
														</div>
														
													</a>
													
												</div>
												
											</div>
											
											
											<a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/stock/600x400/img-14.jpg">
												
												<div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px" style={{backgroundImage: "url('/media/stock/600x600/img-14.jpg')"}}></div>
												
												
												<div className="overlay-layer card-rounded bg-dark bg-opacity-25">
													<i className="bi bi-eye-fill fs-3x text-white"></i>
												</div>
												
											</a>
											
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
			</div> */}

        {/* <div className="mt-sm-n20">
				
				<div className="landing-curve landing-dark-color">
					<svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
					</svg>
				</div>
				
				
				<div className="py-20 landing-dark-bg">
					
					<div className="container">
						
						<div className="d-flex flex-column container pt-lg-20">
							
							<div className="mb-13 text-center">
								<h1 className="fs-2hx fw-bold text-white mb-5" id="pricing" data-kt-scroll-offset="{default: 100, lg: 150}">Clear Pricing Makes it Easy</h1>
								<div className="text-gray-600 fw-semibold fs-5">Save thousands to millions of bucks by using single tool for different
								<br />amazing and outstanding cool and great useful admin</div>
							</div>
							
							
							<div className="text-center" id="kt_pricing">
								
								<div className="nav-group landing-dark-bg d-inline-flex mb-15" data-kt-buttons="true" style={{border: "1px dashed #2B4666;"}}>
									<button onClick={()=>setPricingDuration('monthly')} className={pricingDuration==='monthly'?'btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 mx-2 active':'btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 mx-2'}>Monthly</button>
									<button  onClick={()=>setPricingDuration('annual')} className={pricingDuration==='annual'?'btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 active':'btn btn-color-gray-600 btn-active btn-active-success px-6 py-3'}>Annual</button>
								</div>
								
								
								<div className="row g-10">
									
									<div className="col-xl-4">
										<div className="d-flex h-100 align-items-center">
											
											<div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
												
												<div className="mb-7 text-center">
													
													<h1 className=" mb-5 fw-boldest">Startup</h1>
													
													
													<div className="text-gray-400 fw-semibold mb-5">Best Settings for Startups</div>
													
													
													<div className="text-center">
														<span className="mb-2 text-primary">$</span>
														<span className="fs-3x fw-bold text-primary">{pricingDuration==='monthly'?99:999}</span>
														<span className="fs-7 fw-semibold opacity-50">{pricingDuration==='monthly'?'/ Mon':'/ Ann'}</span>
													</div>
													
												</div>
												
												
												<div className="w-100 mb-10">
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 10 Active Users</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 30 Project Integrations</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800">Keen Analytics Platform</span>
														
														<span className="svg-icon svg-icon-1">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
																<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800">Targets Timelines & Files</span>
														
														<span className="svg-icon svg-icon-1">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
																<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack">
														<span className="fw-semibold fs-6 text-gray-800">Unlimited Projects</span>
														
														<span className="svg-icon svg-icon-1">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
																<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
												</div>
												
												
												<a href="#" className="btn btn-primary">Select</a>
												
											</div>
											
										</div>
									</div>
									
									
									<div className="col-xl-4">
										<div className="d-flex h-100 align-items-center">
											
											<div className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
												
												<div className="mb-7 text-center">
													
													<h1 className="text-white mb-5 fw-boldest">Business</h1>
													
													
													<div className="text-white opacity-75 fw-semibold mb-5">Best Settings for Business</div>
													
													
													<div className="text-center">
														<span className="mb-2 text-white">$</span>
														<span className="fs-3x fw-bold text-white">{pricingDuration==='monthly'?199:1999}</span>
														<span className="fs-7 fw-semibold text-white opacity-75">/{pricingDuration==='monthly'?'/ Mon':'/ Ann'}</span>
													</div>
													
												</div>
												
												
												<div className="w-100 mb-10">
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 10 Active Users</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 30 Project Integrations</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Keen Analytics Platform</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Targets Timelines & Files</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack">
														<span className="fw-semibold fs-6 text-white opacity-75">Unlimited Projects</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
																<rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
												</div>
												
												
												<a href="#" className="btn btn-color-primary btn-active-light-primary btn-light">Select</a>
												
											</div>
											
										</div>
									</div>
									
									
									<div className="col-xl-4">
										<div className="d-flex h-100 align-items-center">
											
											<div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
												
												<div className="mb-7 text-center">
													
													<h1 className=" mb-5 fw-boldest">Enterprise</h1>
													
													
													<div className="text-gray-400 fw-semibold mb-5">Best Settings for Enterprise</div>
													
													
													<div className="text-center">
														<span className="mb-2 text-primary">$</span>
														<span className="fs-3x fw-bold text-primary">{pricingDuration==='monthly'?999:9999}</span>
														<span className="fs-7 fw-semibold opacity-50">{pricingDuration==='monthly'?'/ Mon':'/ Ann'}</span>
													</div>
													
												</div>
												
												
												<div className="w-100 mb-10">
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 10 Active Users</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 30 Project Integrations</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Keen Analytics Platform</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack mb-5">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Targets Timelines & Files</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
													
													<div className="d-flex flex-stack">
														<span className="fw-semibold fs-6 text-gray-800 text-start pe-3">Unlimited Projects</span>
														
														<span className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
															</svg>
														</span>
														
													</div>
													
												</div>
												
												
												<a href="#" className="btn btn-primary">Select</a>
												
											</div>
											
										</div>
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
				
				<div className="landing-curve landing-dark-color">
					<svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
					</svg>
				</div>
				
			</div> */}

        {/**
             * 
             * 
Agriculture & Environment
Sustainable management and conservation of biodiversity resources in our region

Economic Cooperation & Regional Integration
Economic integration within the region

Health & Social Development
Promotion of a healthy and sustainable society within the IGAD member states

Peace & Security
Enhancement of peace, security, and stability in the IGAD Region

Corporate Development Services

Vision 

We envision resilient, diversified and inclusive smallholder farmers, pastoralists, agro-pastoralists and associated cooperatives and groups’ economies, where the communities pursue sustainable and environmentally-friendly livelihoods.  

 

Our mission 

Our mission is to transform the economies and food systems of smallholder farmers, pastoralists, agro-pastoralists and associated cooperatives and groups by making them more inclusive, productive, resilient and sustainable through providing a platform for cooperation, sharing knowledge and exchanging experience in manner that adds value to existing initiatives.  

             */}

        <div className="mt-20 mb-n20 position-relative z-index-2">
          <div className="container">
            <div className="text-left mb-17">
              <h3 className="fs-2hx text-center text-black  mb-5">Vision</h3>
              <div className="fs-5 text-muted fw-bold mb-5">
                We en vision resilient, diversified and inclusive smallholder
                farmers, pastoralists, agro-pastoralists and associated
                cooperatives and groups’ economies, where the communities pursue
                sustainable and environmentally-friendly livelihoods.
              </div>
              <h3 className="fs-2hx text-center text-black  mb-5">Mission</h3>
              <div className="fs-5 text-muted fw-bold">
                Our mission is to transform the economies and food systems of
                smallholder farmers, pastoralists, agro-pastoralists and
                associated cooperatives and groups by making them more
                inclusive, productive, resilient and sustainable through
                providing a platform for cooperation, sharing knowledge and
                exchanging experience in manner that adds value to existing
                initiatives.{" "}
              </div>
            </div>

            <div className="mb-10 mb-lg-20">
              <h3 className="fs-2hx text-black text-center mb-5">
                Our Partners
              </h3>
              <div className="d-flex justify-content-evenly">
                <div className="col-lg-6">
                  <div className="d-flex flex-column justify-content-around h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="d-flex align-items-center">
                      <div className="  mx-5">
                        <a
                          href="#"
                          className=" fw-bold text-hover-primary fs-6"
                        >
                          <img
                            height={"100px"}
                            src="/media/images/world-bank.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                    <div className="d-flex align-items-center">
                      <div className="  mx-5">
                        <a
                          href="#"
                          className=" fw-bold text-hover-primary fs-6"
                        >
                          <img
                            height={"100px"}
                            src="/media/images/usaid.png"
                            className=""
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-stack justify-content-evenly card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
              style={{
                background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)",
                justifyContent: "space-evenly",
              }}
            >
              <div className="my-2 mx-5">
                <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">
                  Join us today,{" "}
                  <span className="fw-normal">
                    to enhance the resilience of pastoralists, agro pastoralists
                    &amp; small holder farmers in the IGAD region
                  </span>
                </div>

                <div className="fs-6 d-none fs-lg-5 text-white fw-semibold opacity-75">
                  Join us today to enhance the resilience of pastoralists, agro
                  pastoralists &amp; small holder farmers in the IGAD region.
                </div>
              </div>
              <div className="align-center">
                <Link
                  to="/auth"
                  className="btn btn-lg btn-outline border-2 btn-outline-white  flex-shrink-0 my-2"
                >
                  Create an Account
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-0">
          <div className="landing-curve landing-dark-color">
            <svg
              viewBox="15 -1 1470 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                fill="#13263c"
              ></path>
            </svg>
          </div>

          <div
            className="landing-dark-bg pt-20"
            style={{ backgroundColor: "#13263c" }}
          >
            <div className="container">
              <div className="row py-10 py-lg-20">
                <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                  <div className="rounded landing-dark-border p-9 mb-10">
                    <h2 className="text-white">
                      Would you need to contact us?
                    </h2>

                    <span className="fw-normal fs-4 text-gray-700">
                      Email us on{" "}
                      <a
                        href="mailto:ifrahteam@igad.int"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        ifrahteam@igad.int
                      </a>
                    </span>
                  </div>
                  <div className="rounded landing-dark-border p-9 mb-10">
                    <h2 className="text-white">Access Our Resources</h2>

                    <span className="fw-normal fs-4 text-gray-700">
                      Visit our portal{" "}
                      <a
                        href="/auth"
                        className="text-white opacity-50 text-hover-primary"
                      >
                        Log In
                      </a>
                    </span>
                  </div>

                  <div className="rounded landing-dark-border p-9">
                    <h2 className="text-white">Stay Connected</h2>
                    <div className=" d-flex flex-column mt-4 flex-start">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.facebook.com/igadsecretariat/"
                        className="mb-6"
                      >
                        <img
                          src="/media/svg/brand-logos/facebook-4.svg"
                          className="h-20px mx-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Facebook
                        </span>
                      </a>

                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.youtube.com/channel/UCDgq86NAvL74tvpQx614qig"
                        className="mb-6"
                      >
                        <img
                          src="/media/svg/brand-logos/youtube-play.svg"
                          className="h-20px mx-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          YouTube
                        </span>
                      </a>

                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://twitter.com/igadsecretariat"
                        className="mb-6"
                      >
                        <img
                          src="/media/svg/brand-logos/twitter.svg"
                          className="h-20px mx-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Twitter
                        </span>
                      </a>

                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.instagram.com/igad_secretariat/"
                        className="mb-6"
                      >
                        <img
                          src="/media/svg/brand-logos/instagram-2-1.svg"
                          className="h-20px mx-2"
                          alt=""
                        />
                        <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                          Instagram
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 ps-lg-16">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex fw-semibold flex-column mx-5">
                      <h4 className="fw-bold text-gray-400 mb-6">
                        More from IGAD
                      </h4>

                      <a
                        target="_blank"
                        href="https://igad.int/agriculture-environment/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        Agriculture and Environment
                      </a>
                      <a
                        target="_blank"
                        href="https://igad.int/economic-cooperation/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        Economic cooperation ad regional integration
                      </a>
                      <a
                        target="_blank"
                        href="https://igad.int/health-social-development/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        Health and Social Development
                      </a>
                      <a
                        target="_blank"
                        href="https://igad.int/peace-security/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        Peace and Security
                      </a>
                      <a
                        target="_blank"
                        href="https://igad.int/corporate-development-services/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        Corporate Development Services
                      </a>
                    </div>

                    <div className="d-flex fw-semibold flex-column ms-lg-20">
                      <h4 className="fw-bold text-gray-400 mb-6">
                        Specialized institutions
                      </h4>

                      <a
                        target="_blank"
                        href="https://cewarn.org"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Conflict Early Warning and Response Mechanism (IGAD
                        CEWARN)
                      </a>
                      <a
                        target="_blank"
                        href="https://www.icpac.net"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Climate Prediction and Application Centre (IGAD
                        CPAC)
                      </a>
                      <a
                        target="_blank"
                        href="https://igadfsi.com"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Foreign Service Institute (IGAD FSI)
                      </a>
                      <a
                        target="_blank"
                        href="https://igadssp.org"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Security Sector Program (IGAD SSP)
                      </a>
                      <a
                        target="_blank"
                        href="https://icpald.org/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Centre for Pastoral Areas and Livestock Development
                        (IGAD CPALD)
                      </a>
                      <a
                        target="_blank"
                        href="https://cve.igad.int/"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Centre of Excellence for Preventing and Countering
                        Violent Extremism (IGAD CEPCVE)
                      </a>
                      <a
                        target="_blank"
                        href="https://igadcaep.org"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Center of Excellence for Climate Adaptation and
                        Environmental Protection (IGAD CAEP)
                      </a>
                      <a
                        target="_blank"
                        href="http://www.istvs-edu.com"
                        className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                      >
                        IGAD Sheikh Technical Veterinary School (IGAD STVS)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="landing-dark-separator"></div>

            <div className="container">
              <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                <div className="d-flex align-items-center order-2 order-md-1">
                  <a href="/" target="_blank">
                    <img
                      alt="Logo"
                      src="/media/logos/ip-logo5.png"
                      className="h-50px h-md-20px"
                    />
                  </a>

                  <span className="mx-5 fs-6 fw-semibold text-gray-600 pt-1">
                    &copy; 2023{" "}
                    <a href="https://igad.int/" target="_blank">
                      IGAD
                    </a>
                    .
                  </span>
                </div>

                <ul className="menu menu-gray-600 menu-hover-primary pull-right fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                  <li className="menu-item mx-5">
                    Powered by{" "}
                    <a
                      href="https://digitalvisionea.com"
                      target="_blank"
                      className="menu-link px-2"
                    >
                      Digital Vision EA
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="13"
                y="6"
                width="13"
                height="2"
                rx="1"
                transform="rotate(90 13 6)"
                fill="currentColor"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </div>

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <span className="svg-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x="13"
              y="6"
              width="13"
              height="2"
              rx="1"
              transform="rotate(90 13 6)"
              fill="currentColor"
            />
            <path
              d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}
