import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {rolesSlice} from "../app/modules/UserManagement/_redux/roles/rolesSlice";
import {usersSlice} from "../app/modules/UserManagement/_redux/users/usersSlice";
import {applicationsSlice} from "../app/modules/Licensing/_redux/applications/applicationsSlice";
import {businessesSlice} from "../app/modules/Licensing/_redux/businesses/businessesSlice";
import {licenseRequirementsSlice} from "../app/modules/Licensing/_redux/settings/license-requirements/licenseRequirementsSlice";
import {licenseSectorsSlice} from "../app/modules/Licensing/_redux/settings/license-sectors/licenseSectorsSlice";
import {licensesSlice} from "../app/modules/Licensing/_redux/settings/licenses/licensesSlice";
import {complaintsSlice} from "../app/modules/ComplaintsManagement/_redux/complaintsSlice";
import {accidentsSlice}from "../app/modules/AccidentsManagement/_redux/accidentsSlice";
import {settingsSlice}from "../app/modules/Settings/_redux/system/settingsSlice";
import {helpdeskSlice}from "../app/modules/Settings/_redux/helpdesk/helpdeskSlice";
import {approvalCommitteesSlice} from "../app/modules/Licensing/_redux/settings/approval-committees/approvalCommitteesSlice";
import {insurancesSlice} from "../app/modules/InsuranceProviders/_redux/insuranceSlice";
import {downloadsSlice} from "../app/modules/Downloads/_redux/downloadSlice";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  users: usersSlice.reducer,
  applications: applicationsSlice.reducer,
  businesses: businessesSlice.reducer,
  licenseRequirements: licenseRequirementsSlice.reducer,
  licenseSectors: licenseSectorsSlice.reducer,
  licenses: licensesSlice.reducer,
  approvalCommittees: approvalCommitteesSlice.reducer,
  complaints: complaintsSlice.reducer,
  accidents: accidentsSlice.reducer,
  settings: settingsSlice.reducer,
  helpdesk: helpdeskSlice.reducer,
  insurances: insurancesSlice.reducer,
  downloads: downloadsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
