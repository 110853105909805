import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
// Reference: https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
import { appConfigs } from "../../../../_igad/_helpers";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import PasswordStrengthBar from 'react-password-strength-bar';

const initialValues = {
  email: "",
  password: "",
  acceptTerms: false,
  accountType: 0,
  companyName: "",
  companyAddress: "",
  companyEmail: "",
  companyPhone: "",
  companyWebsite: "",
  firstname: "",
  lastname: "",
  confirmPassword: "",
  phone: "",
  organization: "",
  areaOfInterest: [],
  serviceCategory: 0,
  otherServiceCategory: "",
  country: 0,
  gender: 0,
};
const accountTypeOptions = [
  { id: 1, name: "Individual" },
  { id: 2, name: "Organization" },
];
const genderOptions = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];

const countryOptions = [
  { id: 1, name: "DJibouti" },
  { id: 2, name: "Ethiopia" },
  { id: 3, name: "Kenya" },
  { id: 4, name: "Somalia" },
  { id: 5, name: "Sudan" },
  { id: 6, name: "Uganda" },
  { id: 7, name: "Eritrea" },
];

const serviceCategoryOptions = [
  { id: 1, name: "Government" },
  { id: 2, name: "UN Agencies" },
  { id: 3, name: "Civil Society" },
  { id: 4, name: "Academia / Researcher" },
  { id: 5, name: "Cooperative" },
  { id: 6, name: "Insurance provider" },
  { id: 7, name: "Other (Specify)" },
  { id: 8, name: "International Organizations" },
  { id: 9, name: "Regional Institutions" },
];

const areaofInterestOptions = [
  { id: 1, name: "Crop" },
  { id: 2, name: "Livestock" },
];

function Registration(props) {
  const { intl } = props;
  const alert = props.history.location.state?.alert;
  const [loading, setLoading] = useState(false);
  const [recaptchaChecked, setRecaptcha] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
    .when("accountType", {
      is: (val) => val === 1,
      then: Yup.string().email('Wrong email format').required("Email is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
    password: Yup.string()
      .min(
        6,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MIN_LENGTH" },
          { name: "Password", min: "6" }
        )
      )
      .max(
        50,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH" },
          { name: "Password", max: "50" }
        )
      )
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED" },
          { name: "Password" }
        )
      ),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.AGREEMENT_REQUIRED" })
    ),
    accountType: Yup.number()
      .min(1, "Please Select a type")
      .max(7, "Select a valid type"),
    companyName: Yup.string().when("accountType", {
      is: (val) => val === 2,
      then: Yup.string()
        .required("Organization Name is required")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    companyAddress: Yup.string().when("accountType", {
      is: (val) => val === 2,
      then: Yup.string()
        .required("Organization Address is required")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    companyEmail: Yup.string().when("accountType", {
      is: (val) => val === 2,
      then: Yup.string()
        .email("Wrong email format")
        .required("Organization Email is required")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    companyPhone: Yup.string().when("accountType", {
      is: (val) => val === 2,
      then: Yup.string()
        .min(10, "Minimum 10 symbols")
        .max(14, "Maximum 14 symbols"),
    }),
    companyWebsite: Yup.string().when("accountType", {
      is: (val) => val === 2,
      then: Yup.string()
        .min(10, "Minimum 10 symbols")
        .max(14, "Maximum 14 symbols"),
    }),
    firstname: Yup.string().when("accountType", {
      is: (val) => val === 1,
      then: Yup.string()
        .required("First Name is required")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    lastname: Yup.string().when("accountType", {
      is: (val) => val === 1,
      then: Yup.string()
        .required("Last Name is required")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    gender: Yup.number().when("accountType", {
      is: (val) => val === 1,
      then: Yup.number().required("Gender is required"),
    }),
    phone: Yup.string().when("accountType", {
      is: (val) => val === 1,
      then: Yup.string()
        .min(10, "Minimum 10 symbols")
        .max(14, "Maximum 14 symbols"),
    }),
    organization: Yup.string().when("accountType", {
      is: (val) => val === 1,
      then: Yup.string()
        .min(3, "Minimum 10 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    country: Yup.number()
      .min(1, "Please Select a country")
      .max(7, "Select a valid country")
      .required("Country is required"),
    serviceCategory: Yup.number()
      .min(1, "Please Select a service")
      .max(7, "Select a valid service")
      .required("Service Category is required"),
    otherServiceCategory: Yup.string().when("serviceCategory", {
      is: (val) => val === 7,
      then: Yup.string().required("Enter other service category"),
    }),
    areaofInterest: Yup.array(Yup.number()).min(
      1,
      "Select an area of interest"
    ),
    confirmPassword: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    autoComplete: "off",
    onSubmit: (values, { setStatus, setSubmitting }) => {
      var navigateTo = "";
      var navigateAlert = {};
  
      setSubmitting(true);
      enableLoading();
      register(
        values.password,
        values.email===''||values.email===null?values.companyEmail:values.email,
        values.firstname,
        values.lastname,
        values.gender,
        values.confirmPassword,
        values.organization,
        values.country,
        values.phone,
        values.serviceCategory,
        values.accountType,
        values.areaOfInterest,
        values.otherServiceCategory,
        values.companyName,
        values.companyAddress,
        values.companyEmail,
        values.companyPhone,
        values.companyWebsite
      )
        .then((resp) => {
          console.log(resp.data);
          if (resp.data.status === 'success') {
            // Register success
            navigateAlert = {
              msg: resp.data.message,
              type: "success",
            };
            navigateTo = "/auth/login";
          } else {
              navigateAlert = {
                msg: resp.data.message,
                type: "danger",
              };
              navigateTo = "/auth/login";
          }
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus({
            msg: intl.formatMessage({ id: "AUTH.REGISTER.ERROR" }),
            type: "danger",
          });
          disableLoading();
        })
        .finally(() => {
          if (navigateTo !== "")
            props.history.push(navigateTo, { alert: navigateAlert });
        });
    },
  });

  function onRecaptchaChange(value) {
    // console.log("Captcha value:", value);
    value ? setRecaptcha(true) : setRecaptcha(false);
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESCR" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status ? (
          <div
            className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
          >
            <div className="alert-text font-weight-bold">
              {formik.status.msg}
            </div>
          </div>
        ) : alert ? (
          <div
            className={`mb-10 alert alert-custom alert-light-${alert.type} alert-dismissible`}
          >
            <div className="alert-text font-weight-bold">{alert.msg}</div>
          </div>
        ) : null}
        {/* end: Alert */}
        {/* begin::Form group Service category */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Type of Account</label>
        <Select
          name='accountType'
          options = {accountTypeOptions.map((accountType) => ({"value":accountType.id,"label":accountType.name}))} 
          onChange={(v)=>{formik.setFieldValue('accountType',v?.value)}}
        />
        {formik.touched.accountType && formik.errors.accountType && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.accountType}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {formik.values?.accountType === 1 && <>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstname"
            )}`}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastname"
            )}`}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Gender</label>
          <Select
            name='gender' 
            options = {genderOptions.map((genderOption) => ({"value":genderOption.id,"label":genderOption.name}))} 
            onChange={(v)=>{formik.setFieldValue('gender',v?.value)}}
          />
          {formik.touched.gender && formik.errors.gender && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.gender}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
          <div  
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phone"
            )}`}
          >
            <PhoneInput
              country={'ke'}
              preferredCountries={['ke','ug','so','et','ss','sd','dj','er']}
              onChange={phone=>formik.setFieldValue('phone',phone)}
              // {...formik.getFieldProps('phone')}
              
            />
          </div>
          
        
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization</label>
          <input
            placeholder='Organization'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('organization')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "organization"
            )}`}
          />
          {formik.touched.organization && formik.errors.organization && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.organization}</span>
              </div>
            </div>
          )}
        </div>

      </>}
      {formik.values?.accountType === 2 && <>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Name</label>
          <input
            placeholder='Organization name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyName')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "companyName"
            )}`}
          />
          {formik.touched.companyName && formik.errors.companyName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Address</label>
          <input
            placeholder='Organization address'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyAddress')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "companyAddress"
            )}`}
          />
          {formik.touched.companyAddress && formik.errors.companyAddress && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyAddress}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Email</label>
          <input
            placeholder='Organization email'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyEmail')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "companyEmail"
            )}`}
          />
          {formik.touched.companyEmail && formik.errors.companyEmail && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyEmail}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Website</label>
          <input
            placeholder='Organization website'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyWebsite')}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "companyWebsite"
            )}`}
          />
          {formik.touched.companyWebsite && formik.errors.companyWebsite && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyWebsite}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Organization Phone</label>
        <div  
          className={(
            'form-control form-control-solid h-auto py-5 px-6 ',
            {'is-invalid': formik.touched.companyPhone && formik.errors.companyPhone},
            {
              'is-valid': formik.touched.companyPhone && !formik.errors.companyPhone,
            }
          )}
        >
          <PhoneInput
            country={'ke'}
            preferredCountries={['ke','ug','so','et','ss','sd','dj','er']}
            onChange={phone=>formik.setFieldValue('companyPhone',phone)}
          />
        </div>
        {formik.touched.companyPhone && formik.errors.companyPhone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.companyPhone}</span>
            </div>
          </div>
        )}
      </div>
      </>}
      {/* begin::Form group Country */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Country</label>

        <Select
          name='country'
          isMulti={false}
          options = {countryOptions.map((country) => ({"value":country.id,"label":country.name}))} 
          onChange={(v)=>{formik.setFieldValue('country',v?.value)}}
        />
  
        {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Service category */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Service category</label>

        <Select
          id='serviceCategory'
          isMulti={false}
          options = {serviceCategoryOptions.map((serviceCategory) => ({"value":serviceCategory.id,"label":serviceCategory.name}))} 
          onChange={(v)=>{formik.setFieldValue('serviceCategory',v?.value)}}
        />

        <div className='py-6'>
          {formik.values.serviceCategory === 7 && 
          <input  
            placeholder='Other Service category' 
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otherServiceCategory"
            )}`}
            type="text" 
            {...formik.getFieldProps('otherServiceCategory')}  />}
          </div>
        {formik.touched.serviceCategory && formik.errors.serviceCategory && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.serviceCategory}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Service category */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Areas of Interest </label>
        <Select
          name='areaOfInterest[]'
          isMulti={true}
          options = {areaofInterestOptions.map((areaOfInterest) => ({"value":areaOfInterest.id,"label":areaOfInterest.name}))} 
          onChange={(v)=>{
            formik.setFieldValue('areaOfInterest',v.map((val)=>val?.value))
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.areaOfInterest && formik.errors.areaOfInterest && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.areaOfInterest}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='new-password'
              {...formik.getFieldProps('password')}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <PasswordStrengthBar password={formik.values?.password} />
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "confirmPassword"
          )}`}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
          <input type="text" name="username" className="d-none" />
        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span></span> &nbsp;&nbsp; I agree to the &nbsp;
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </Link>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center- mb-3">
          <ReCAPTCHA
            sitekey={appConfigs.recaptcha_site_key}
            onChange={onRecaptchaChange}
            onExpired={onRecaptchaChange}
            onErrored={onRecaptchaChange}
          />
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms ||
              !recaptchaChecked
            }
            className="btn btn-primary btn-block font-weight-bold px-9 py-4 my-3 mx-4-"
          >
            <span>Sign Up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          {/* <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
            </button>
          </Link> */}
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
