import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import * as auth from "../_redux/authRedux";
import ReCAPTCHA from "react-google-recaptcha";
import { appConfigs } from "../../../../_igad/_helpers";
import { Alert, Spinner } from "react-bootstrap";
import { validatePortalToken } from "../_redux/authCrud";

function MiniGridRegistration(props) {

    let { param:authKey,document:documentType } = useParams();
    const [loading, setLoading] = useState(false);
    const [docType, setDocType] = useState(null);
    const [portalUser, setPortalUser] = useState(null);
    const [tokenProcessed, setTokenProcessed] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [recaptchaChecked, setRecaptcha] = useState(false);

    function onRecaptchaChange(value) {
        (value) ? setRecaptcha(true) : setRecaptcha(false);
    }


    const validateToken = (key) => {
        setLoading(true);
        validatePortalToken(key)
        .then((resp) => {
            if (resp.data.result_code === 1) {
                const user = resp.data.user;
                props.login(resp.data.token);
                if(user){
                    setPortalUser(user);
                    setValidToken(true);
                    setDocType(documentType);
                } else {
                    setValidToken(false);
                }
            }  else {
                setValidToken(false);
            }
            setTokenProcessed(true);
          }).catch(() => {
            setTokenProcessed(true);
          }).finally(() => {
            setTokenProcessed(true);
            setLoading(false);
          });
    }



    useEffect(() => {  
       validateToken(authKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[authKey]);

    return (
        <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Mini-Grid Registration</h3>
            <p className="text-muted font-weight-bold">Register Mini-Grid license</p>
        </div>

            {!tokenProcessed && <Alert variant="info">
                <Spinner variant="warning" animation="grow" />
                <span className="ml-5">Processing request ...</span> 
            </Alert>}

            {tokenProcessed && validToken && portalUser && <Alert variant="success">
                <h4>Please proceed to continue the registration of your Mini-grid license </h4>
                <p>Welcome <strong>{portalUser.FirstName} {portalUser.MiddleName} {portalUser.LastName}!</strong></p>
                <p><em>Please confirm the details below and proceed</em></p>
                <ul>
                    <li><strong>Name of Business: </strong> {portalUser.BusinessName}</li>
                    <li><strong>Email: </strong> {portalUser.Email}</li>
                    <li><strong>Mobile Number: </strong> {portalUser.MobileNumber}</li>
                    <li><strong>KRA PIN: </strong> {portalUser.KRAPIN}</li>
                    <li><strong>National ID: </strong> {portalUser.NationalID}</li>
                    <li><strong>KRAPIN: </strong> {portalUser.NationalID}</li>
                    <li><strong>License Type: </strong> {docType}</li>
                </ul>
            </Alert>}
            
            {tokenProcessed && !validToken && <Alert variant="danger">
                <h4>Sorry</h4> 
                <p>We are unable to verify credentials please consult <a href="mailto:licensing@igad.go.ke">licensing@igad.go.ke</a> for assistance </p>
            </Alert>}

            <div className="form-group d-flex flex-wrap flex-center- mb-3">
            <ReCAPTCHA
                sitekey={appConfigs.recaptcha_site_key}
                onChange={onRecaptchaChange}
                onExpired={onRecaptchaChange}
                onErrored={onRecaptchaChange}
            />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
            <Link
                to="/dashboard"
                disabled={
                !recaptchaChecked||!validToken||!tokenProcessed
                }
                className="btn btn-primary btn-block font-weight-bold px-9 py-4 my-3 mx-4-"
            >
                <span>Proceed</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
            </Link>
            </div>
        </div>
    );
}

export default connect(null, auth.actions)(MiniGridRegistration);
