import {createSlice} from "@reduxjs/toolkit";

const initialDownloadsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  downloadForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const downloadsSlice = createSlice({
  name: "downloads",
  initialState: initialDownloadsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getdownloadById
    downloadFetched: (state, action) => {
      state.actionsLoading = false;
      state.downloadForEdit = action.payload.downloadForEdit;
      state.error = null;
    },
    // findDownloads
    downloadsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createDownload
    downloadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      console.log("Download Item: ",action.payload.download);
      state.entities.push(action.payload.download);
    },
    // updateDownload
    downloadUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.download.id) {
          return action.payload.download;
        }
        return entity;
      });
    },
    // deleteDownload
    downloadDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteDownloads
    downloadsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // downloadsUpdateState
    downloadsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
