export const appPermissions = {
    permissions: [
        //==================================
        {
            id: "IGAD_1",
            name: 'accessToUsers',
            level: 1,
            title: 'Manage Users'
        },
        //----------------------------------
        {
            id: "IGAD_1a",
            name: 'accessToUsersRead',
            level: 2,
            parentId: "IGAD_1",
            title: 'Read'
        },
        {
            id: "IGAD_1b",
            name: 'accessToUsersEdit',
            level: 2,
            parentId: "IGAD_1",
            title: 'Edit'
        },
        {
            id: "IGAD_1c",
            name: 'accessToUsersDelete',
            level: 2,
            parentId: "IGAD_1",
            title: 'Delete'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "IGAD_2",
            name: 'accessToRoles',
            level: 1,
            title: 'Manage User Roles & Permissions'
        },
        //----------------------------------
        {
            id: "IGAD_2a",
            name: 'accessToRolesRead',
            level: 2,
            parentId: "IGAD_2",
            title: 'Read'
        },
        {
            id: "IGAD_2b",
            name: 'accessToRolesEdit',
            level: 2,
            parentId: "IGAD_2",
            title: 'Edit'
        },
        {
            id: "IGAD_2c",
            name: 'accessToRolesDelete',
            level: 2,
            parentId: "IGAD_2",
            title: 'Delete'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "IGAD_3",
            name: 'accessToExpressionOfInterestRead',
            level: 1,
            title: 'Manage EOI Applications'
        },
        //----------------------------------
        {
            id: "IGAD_3a",
            name: 'accessToExpressionOfInterestReview',
            level: 2,
            parentId: "IGAD_3",
            title: 'Review'
        },
        {
            id: "IGAD_3b",
            name: 'accessToExpressionOfInterestCommittee',
            level: 2,
            parentId: "IGAD_3",
            title: 'Commitee Review'
        },
        {
            id: "IGAD_3c",
            name: 'accessToExpressionOfInterestDirectorApproval',
            level: 2,
            parentId: "IGAD_3",
            title: 'Director Approval'
        },
        {
            id: "IGAD_3d",
            name: 'accessToExpressionOfInterestRead',
            level: 2,
            parentId: "IGAD_3",
            title: 'Read'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "IGAD_4",
            name: 'accessToTariffApplicationsRead',
            level: 1,
            title: 'Manage Tariff Applications'
        },
        //----------------------------------
        {
            id: "IGAD_4a",
            name: 'accessToTariffApplicationsReview',
            level: 2,
            parentId: "IGAD_4",
            title: 'Review'
        },
        {
            id: "IGAD_4b",
            name: 'accessToTariffApplicationsCommittee',
            level: 2,
            parentId: "IGAD_4",
            title: 'Committee Review'
        },
        {
            id: "IGAD_4c",
            name: 'accessToTariffApplicationsDirectorApproval',
            level: 2,
            parentId: "IGAD_4",
            title: 'Director Review'
        },
        {
            id: "IGAD_4d",
            name: 'accessToTariffApplicationsRead',
            level: 2,
            parentId: "IGAD_4",
            title: 'Read'
        },
        //----------------------------------
        //==================================
        

        //==================================
        {
            id: "IGAD_5",
            name: 'accessToLicenseApplicationsRead',
            level: 1,
            title: 'Manage License Applications'
        },
        //----------------------------------
        {
            id: "IGAD_5a",
            name: 'accessToLicenseApplicationsReview',
            level: 2,
            parentId: "IGAD_5",
            title: 'Review'
        },
        {
            id: "IGAD_5b",
            name: 'accessToLicenseApplicationsCommittee',
            level: 2,
            parentId: "IGAD_5",
            title: 'Committee Review'
        },
        {
            id: "IGAD_5c",
            name: 'accessToLicenseApplicationsDirectorApproval',
            level: 2,
            parentId: "IGAD_5",
            title: 'Director Review'
        },
        {
            id: "IGAD_5d",
            name: 'accessToLicenseApplicationsRead',
            level: 2,
            parentId: "IGAD_5",
            title: 'Read'
        },

        //==================================
        {
            id: "IGAD_6",
            name: 'accessToSettings',
            level: 1,
            title: 'Manage System Settings'
        },
        //----------------------------------
        {
            id: "IGAD_6a",
            name: 'accessToSettingsRead',
            level: 2,
            parentId: "IGAD_6",
            title: 'Read'
        },
        {
            id: "IGAD_6b",
            name: 'accessToSettingsEdit',
            level: 2,
            parentId: "IGAD_6",
            title: 'Edit'
        },
        //----------------------------------
        //==================================

    ]
};