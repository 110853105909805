import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resendVerification, verifyEmail } from "../_redux/authCrud";
import { useParams } from "react-router";
import { Spinner } from "react-bootstrap";

const initialValues = {
  email: "",
};


function VerifyEmail(props) {
  let { token } = useParams();
  let verifying = (token === undefined) ? false : true;
  const { intl } = props;
  var alert = props.history.location.state?.alert;
  (!alert && !verifying) ? alert = {msg: intl.formatMessage({id: "AUTH.VERIFY.EMAIL.INTRO",}), type: 'info'} : alert = props.history.location.state?.alert;
  const [isRequested, setIsRequested] = useState(false);
  const VerifyEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: "AUTH.VALIDATION.INVALID",}, {name: 'Email'}))
      .min(5, intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH",}, {name: 'Email', min: '5'}))
      .max(250, intl.formatMessage({id: "AUTH.VALIDATION.MAX_LENGTH",}, {name: 'Email', max: '250'}))
      .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED",}, {name: 'Email'})),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  function displayIntro(){
    return !verifying ? <FormattedMessage id="AUTH.VERIFY.EMAIL.DESCRIPTION" /> : <FormattedMessage id="AUTH.VERIFY.EMAIL.PROCESSING" />
  }

  var navigateTo = '';
  var navigateAlert = {};

  const verifyWithToken = verifying ? () => {
    verifyEmail(token)
      .then((resp) => {
        // console.log(resp.data);
        if(resp.data.status === 'success') {
          navigateAlert = {msg: intl.formatMessage({id: "AUTH.VERIFY.EMAIL.VERIFICATION_SUCCESSFUL",}), type: 'success'};
          navigateTo = "/auth/login";
        } else {
          navigateAlert = {msg: intl.formatMessage({id: "AUTH.VERIFY.EMAIL.VERIFICATION_ERROR",}), type: 'danger'};
          navigateTo = "/auth/verify-email-request";
        }
      })
      .catch(() => {
        navigateAlert = {msg: intl.formatMessage({id: "AUTH.VERIFY.EMAIL.VERIFICATION_ERROR",}), type: 'danger'};
        navigateTo = "/auth/login";
      }).finally(() => {
        if (navigateTo !== '') props.history.push(navigateTo, {alert: navigateAlert});
      });
  } : () => {};

  verifyWithToken();

  const formik = useFormik({
    initialValues,
    validationSchema: VerifyEmailSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      
      // var navigateTo = '';
      // var navigateAlert = {};

      setSubmitting(true);
      resendVerification(values.email)
        .then((resp) => {
          if(resp.data.status === 'success') {
            navigateAlert = {msg: intl.formatMessage({id: "AUTH.VERIFY.EMAIL.RESEND_VERIFICATION_SUCCESSFUL",}, {email: values.email}), type: 'success'};
          } else {
            navigateAlert = {msg: intl.formatMessage({id: "AUTH.VERIFY.EMAIL.RESEND_VERIFICATION_ERROR",}), type: 'danger'};
          }
          navigateTo = "/auth/login";
          // setIsRequested(true);
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(intl.formatMessage({id: "AUTH.VERIFY.EMAIL.RESEND_VERIFICATION_ERROR",},));
        }).finally(() => {
          if (navigateTo !== '') props.history.push(navigateTo, {alert: navigateAlert});
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1"><FormattedMessage id="AUTH.VERIFY.EMAIL" /></h3>
            <div className="text-muted font-weight-bold">{ displayIntro() }</div>
          </div>
          <div style={{ display: !verifying ? "none" : "flex", alignItems: "center", justifyContent: "center" }} className="mb-10">
            <Spinner animation="border" variant="info" />
          </div>
          <form onSubmit={formik.handleSubmit} style={{ display: verifying ? "none" : "block" }} className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
            {formik.status ? (
              <div className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">{formik.status.msg}</div>
              </div>
            ) : alert ? (
              <div className={`mb-10 alert alert-custom alert-light-${alert.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">{alert.msg}</div>
              </div>
            ) : null}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                placeholder="Your email"
                autoComplete="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary btn-block font-weight-bold px-9 py-4 my-3 mx-4-"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.VERIFY.EMAIL.SUBMIT" />
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(VerifyEmail));
