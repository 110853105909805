import React from "react"; //, { useEffect } 
import {  shallowEqual, useSelector } from "react-redux";
// import * as actions from "../modules/Licensing/_redux/applications/applicationsActions";
// import { Alert } from "react-bootstrap";
// import { Maps } from "../widgets";
// import { SitesPage } from "../modules/Sites/SitesPage";
// import { ApplicationProcess } from "../../_igad/layout/components/extras/ApplicationProcess";
// import { Link } from "react-router-dom";
import {UserTile,TilesWidget12} from "../../_igad/_partials/widgets";
//import {ListsWidget1,ListsWidget3,ListsWidget4} from "../../_igad/_partials/widgets";


export function DashboardPage() {
    const { user } = useSelector(
        (state) => ({
            user: state.auth.user || {},
        }),
        shallowEqual
    );
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="primary p-4" style={{borderRadius:'5px',color:'#fff',backgroundImage: "linear-gradient(to right, #48DC8C, #019CCD)" }}>Welcome Back {user?.first_name} {user?.last_name}!</h3>
                </div>
            </div>
           <div className="row">
                <div className="col-lg-4">
                    <UserTile className="gutter-b" count="456" baseColor="info" statName="Total Users" />
                </div>
                <div className="col-lg-4">
                    <TilesWidget12 className="gutter-b" count={'86,000'} statName="Insurance Products" />
                </div>
                <div className="col-lg-4 d-flex flex-column">
                    <UserTile className="gutter-b" count="324" baseColor="primary" statName="Total Insurers" />
                </div>
           </div>
           {/* <div className="row">
                <div className="col-lg-4">
                    <ListsWidget1 className="gutter-b" />
                </div>
                <div className="col-lg-4">
                    <ListsWidget3 className="gutter-b" />
                </div>
                <div className="col-lg-4">
                    <ListsWidget4 className="gutter-b" />
                </div>
           </div> */}
        
           
        </>
    );
}
