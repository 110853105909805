import {createSlice} from "@reduxjs/toolkit";

const initialApplicationsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  applicationForEdit: undefined,

  expressionOfInterestDrafts: undefined,
  expressionOfInterest: undefined,
  expressionsOfInterest: [],
  expressionsOfInterestTotalCount: undefined,
  expressionsOfInterestUpdateCounts: undefined,

  monthlyReport: undefined,
  
  constructionPermit: undefined,
  constructionPermits: [],
  constructionPermitsTotalCount: undefined,

  corporateInformation: undefined,
  corporateInformations: [],
  corporateInformationsTotalCount: undefined,

  tariffApplication: undefined,
  tariffApplications: [],
  tariffApplicationsTotalCount: undefined,

  licenseApplication: undefined,
  licenseApplications: [],
  licenseApplicationsTotalCount: undefined,

  monthlyPerformanceReport: undefined,
  monthlyPerformanceReports: [],
  monthlyPerformanceReportsTotalCount: undefined,

  constructionPermitReview: undefined,
  constructionPermitReviews: [],
  constructionPermitReviewsTotalCount: undefined,

  licenseApplicationReview: undefined,
  licenseApplicationReviews: [],
  licenseApplicationReviewsTotalCount: undefined,

  tariffApplicationReview: undefined,
  tariffApplicationReviews: [],
  tariffApplicationReviewsTotalCount: undefined,

  expressionOfInterestReview: undefined,
  expressionOfInterestReviews: [],
  expressionOfInterestReviewTotalCount: undefined,

  licensePayment: undefined,
  STKResult: undefined,
  licenceInvoicing: {
    "LicenceInvoiceID": 72,
    "SubsequentID": 207326,
    "SubsequentTableID": 1,
    "InvoiceNumber": "AP207326",
    "InvoicedAmount": 0,
    "PaidAmount": null,
    "LicenceReceiptNo": null,
    "LicenceReceiptDate": null,
    "PaymentMethodID": null,
    "LastTransactionCode": null,
    "Datecreated": "2021-12-06 13:35:05.997"
  },
  licenseInvoices: [
    {
      "LicenceInvoiceLineID": 128,
      "LicenceInvoiceID": "72",
      "LicencePaymentTypeID": 1,
      "Quantity": 1,
      "ItemFee": null,
      "DateCreated": "2021-12-06 13:35:34.963",
      "Deleted": 0
    }
],

  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const applicationsSlice = createSlice({
  name: "applications",
  initialState: initialApplicationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.error("Redux",state.error); //redux debug
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getApplicationById
    applicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.applicationForEdit = action.payload.applicationForEdit;
      state.error = null;
    },
    // fileUploaded
    fileUploaded: (state, action) => {
      state.fileUploaded = action.payload.fileUploaded;
      
    },
    // findApplications
    applicationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    STKPushFetched: (state, action) => {
      const { STKResult } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.STKResult = STKResult;
    },
    //paymentInvoicesFetched
    paymentInvoicesFetched: (state, action) => {
      const { licenceInvoicing, licenseInvoices } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.licenceInvoicing = licenceInvoicing;
      state.licenseInvoices = licenseInvoices;
    },
    //construction permit
    constructionPermitsFetched: (state, action) => {
      const { totalCount, constructionPermits } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.constructionPermits = constructionPermits;
      state.constructionPermitsTotalCount = totalCount;
    },
    constructionPermitFetched: (state, action) => {
      state.actionsLoading = false;
      state.constructionPermit = action.payload.constructionPermit;
      state.error = null;
    },
    constructionPermitUpdated: (state, action) => {
      state.actionsLoading = false;
      state.constructionPermit = action.payload.constructionPermit;
      state.error = null;
    },
    constructionPermitCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.constructionPermit = action.payload.constructionPermit;
      if(state.constructionPermit.applicationStep === 0){
        state.constructionPermits.push(action.payload.constructionPermit);
      }
      
    },

    licensePaymentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.licensePayment = action.payload.licensePayment;
    },

    monthlyReportCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.monthlyPerformanceReport = action.payload.monthlyReport;      
    },
    
    //tariff application
    tariffApplicationsFetched: (state, action) => {
      const { totalCount, tariffApplications } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.tariffApplications = tariffApplications;
      state.tariffApplicationsTotalCount = totalCount;
    },
    tariffApplicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.tariffApplication = action.payload.tariffApplication;
      state.error = null;
    },
    tariffApplicationUpdated: (state, action) => {
      state.actionsLoading = false;
      state.tariffApplication = action.payload.tariffApplication;
      state.error = null;
    },
    tariffApplicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.tariffApplication = action.payload.tariffApplication;
      if(state.tariffApplication.applicationStep === 0){
        state.tariffApplications.push(action.payload.tariffApplication);
      }
    },
    tariffApplicationsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.tariffApplications = action.payload.tariffApplications;
      var tariffApplications =  action.payload.tariffApplications;
      if(tariffApplications.length > 0){
        tariffApplications.map((tariff,tariffIndex) => {
          state.tariffApplications.push(tariff);
          return true;
        })
        
      }
    },

    tariffApplicationsUpdatedbyEOI: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.tariffApplications = action.payload.tariffApplications;
      var tariffApplications =  action.payload.tariffApplications;
      if(tariffApplications.length > 0){
        tariffApplications.map((tariff) => {
          const itemIndex = state.tariffApplications.findIndex((t) =>t._id === tariff._id);
          if(itemIndex !== -1){
            state.tariffApplications[itemIndex] = tariff;
          }
          return true;
        })
        
      }
    },

     //monthly reports Application
     monthlyPerformanceReportsFetched: (state, action) => {
      const { totalCount, monthlyPerformanceReports } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.monthlyPerformanceReports = monthlyPerformanceReports;
      state.monthlyPerformanceReportsTotalCount = totalCount;
    },

       //monthly reports Application
       monthlyPerformanceReportFetched: (state, action) => {
      const { monthlyPerformanceReport } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.monthlyPerformanceReport = monthlyPerformanceReport;
    },
    
    //license Application
    licenseApplicationsFetched: (state, action) => {
      const { totalCount, licenseApplications } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.licenseApplications = licenseApplications;
      state.licenseApplicationsTotalCount = totalCount;
    },
    licenseApplicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.licenseApplication = action.payload.licenseApplication;
      state.error = null;
    },
    licenseApplicationUpdated: (state, action) => {
      state.actionsLoading = false;
      state.licenseApplication = action.payload.licenseApplication;
      state.error = null;
    },
    licenseApplicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.licenseApplication = action.payload.licenseApplication;
      if(state.licenseApplication.applicationStep === 0){
        state.licenseApplications.push(action.payload.licenseApplication);
      }
      
    },
    licenseApplicationsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.licenseApplication = action.payload.licenseApplication;
      var licenseApplications =  action.payload.licenseApplications;
      if(licenseApplications.length > 0){
        licenseApplications.map((licenseApplication) => {
          state.licenseApplications.push(licenseApplication);
          return true;
        })
        
      }      
    },

    //corporate information
    corporateInformationFetched: (state, action) => {
      state.actionsLoading = false;
      state.corporateInformation = action.payload.corporateInformation;
      state.error = null;
    }, 
    corporateInformationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.corporateInformations.push(action.payload.corporateInformation);
      state.corporateInformation = action.payload.corporateInformation;
    },
    expressionOfInterestReviewCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.expressionOfInterestReview  = action.payload.expressionOfInterestReview;
    },
    expressionOfInterestReviewsFetched: (state, action) => {
      const { totalCount, expressionOfInterestReviews } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.expressionOfInterestReviews = expressionOfInterestReviews;
      state.expressionOfInterestReviewsTotalCount = totalCount;
    },
    constructionPermitReviewCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.constructionPermitReview  = action.payload.constructionPermitReview;
    },

    tariffApplicationReviewCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.tariffApplicationReview  = action.payload.tariffApplicationReview;
    },
    licenseApplicationReviewCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.licenseApplicationReview  = action.payload.licenseApplicationReview;
    },

    constructionPermitReviewsFetched: (state, action) => {
      const { totalCount, constructionPermitReviews } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.constructionPermitReviews = constructionPermitReviews;
      state.constructionPermitReviewsTotalCount = totalCount;
    },
    //expression of interest
    expressionsOfInterestFetched: (state, action) => {
      const { totalCount, expressionsOfInterest } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.expressionsOfInterest = expressionsOfInterest;
      state.expressionsOfInterestTotalCount = totalCount;
    },
    expressionsOfInterestFetchedCount: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.expressionsOfInterestTotalCount = totalCount;
    },

    expressionsOfInterestDependenciesUpdated: (state, action) => {
      const { updateCounts } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.expressionsOfInterestUpdateCounts = updateCounts;
    },
    
    expressionOfInterestFetched: (state, action) => {
      state.actionsLoading = false;
      state.expressionOfInterest = action.payload.expressionOfInterest;
      state.error = null;
    },
    expressionOfInterestUpdated: (state, action) => {
      state.actionsLoading = false;
      state.expressionOfInterest = action.payload.expressionOfInterest;
      state.error = null;
    },
    expressionOfInterestCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.expressionOfInterest = action.payload.expressionOfInterest;
      if(action.payload.expressionOfInterest.isSubmitted === 1){
        state.expressionsOfInterest.push(action.payload.expressionOfInterest);
      }
    },

    expressionsOfInterestCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      action.payload.expressionsOfInterest.map(expression => {
        state.expressionsOfInterest.push(expression);
        return true;
      });
    },

    // create EOI Draft
    expressionOfInterestDraftCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.expressionOfInterestDrafts.push(action.payload.expressionOfInterest);
    },

    // update EOI Draft
    expressionOfInterestDraftUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.expressionOfInterestDrafts.push(action.payload.expressionOfInterest);

      state.expressionOfInterestDrafts = state.expressionOfInterestDrafts.map((entity,entityIndex) => {
        if (entityIndex === action.payload.expressionOfInterest.index) {
          return action.payload.expressionOfInterest;
        }
        return entity;
      });
    },

    // delete EOI Draft
    expressionOfInterestDraftDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.expressionOfInterestDrafts = state.expressionOfInterestDrafts.splice(action.payload.expressionOfInterest.index, 1);
    },


    // createApplication
    applicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.application);
    },
    
    // updateApplication
    applicationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.application.id) {
          return action.payload.application;
        }
        return entity;
      });
    },
    // deleteApplication
    applicationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteApplications
    applicationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },

    
    // applicationsUpdateState
    applicationsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // fetchMyApplications
    myApplicationsFetched: (state, action) => {
      const { totalCount, entities, individual_applications } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.individualApplications = individual_applications;
    },
  }
});
